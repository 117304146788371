import _ from "lodash";
import dayjs from "dayjs";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ColDef, CellClickedEvent, RowSelectedEvent } from "ag-grid-community";
import { Radio, Checkbox, Skeleton, Button, message, Badge } from "antd";
import type { RadioChangeEvent } from "antd";

import QUERY_KEYS from "services/api/queryKeys";
import { getHomeResultURL } from "routes/constants";
import API from "services/api";
import { MAP_COLOR_DPE } from "constants/index";

import "styles/control.scss";

const MAP_COLOR_TABLE: Record<number, string> = {
  0: "#fff",
  1: "#6EB86E20",
  2: "#FEB87020",
  3: "#B4231820",
};

function ControlPage() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const gridApi = useRef<any>();
  const [messageApi, contextHolder] = message.useMessage();
  const [controlFilter, setControlFilter] = useState("all");
  const [selectedNodeIds, setSelectedNodeIds] = useState<(number | null)[]>([]);
  const [hashs, setHashs] = useState([]);

  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.GET_CONTROLS_RESULT, controlFilter],
    queryFn: () => API.mock.getControlsResult(controlFilter),
  });

  const { mutateAsync: mutatePostRowControl, isPending } = useMutation({
    mutationFn: (params: any) => API.mock.postSelectedRowControl(params),
  });

  useEffect(() => {
    if (_.isEmpty(hashs) && !_.isEmpty(data)) {
      setHashs(data?.hashs || []);
    }
  }, [data, hashs]);

  const onChange = (e: RadioChangeEvent) => {
    setControlFilter(e.target.value);
  };

  const handleClickCell = (event: CellClickedEvent) => {
    if (event.colDef.colId === "ag-Grid-ControlsColumn") {
      return;
    }
    navigate(getHomeResultURL(event.data.id));
  };

  const handleChangeSelectedRow = (event: RowSelectedEvent) => {
    const nodeIds = event.api.getSelectedNodes().map((item) => item?.data?.id);
    setSelectedNodeIds(nodeIds);
  };

  const handleSendSelectedRow = async () => {
    try {
      await mutatePostRowControl({
        selected: selectedNodeIds,
      });

      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_CONTROLS_RESULT, controlFilter],
      });

      gridApi.current?.api?.deselectAll();

      setSelectedNodeIds([]);
      messageApi.open({
        type: "success",
        content: "Success",
      });
    } catch (e) {
      messageApi.open({
        type: "error",
        content: "Failed",
      });
    }
  };

  const rowData = useMemo(() => {
    return data?.table_values;
  }, [data]);

  // Column Definitions: Defines & controls grid columns.
  const colTypes = useMemo(() => {
    return {
      longText: {
        width: 400,
      },
      mediumText: {
        width: 200,
      },
      shortText: {
        width: 100,
      },
    };
  }, []);

  const colDefs: ColDef[] = useMemo(() => {
    if (_.isEmpty(data)) {
      return [];
    }

    const columns = data?.table_values[0]
      ? Object.keys(data?.table_values[0]).map((key, index) => {
          if (key === "status") {
            return {
              field: key,
              headerName: key,
              type: "shortText",
              cellRenderer: (item: any) => {
                return <Checkbox checked={!!item.data?.status} />;
              },
            };
          }

          if (key === "dpe") {
            return {
              field: key,
              headerName: key,
              type: "shortText",
              cellRenderer: (item: any) => {
                return (
                  <Badge
                    count={item.value}
                    color={MAP_COLOR_DPE[item.value]}
                    style={{ minWidth: "40px" }}
                  />
                );
              },
            };
          }

          return {
            field: key,
            headerName: key,
            type: "mediumText",
            cellStyle: (params: any) => {
              if (!["id", "hash"].includes(String(params.colDef.colId))) {
                return {
                  backgroundColor: MAP_COLOR_TABLE[params.value] || "#fff",
                };
              }
              return null;
            },
          };
        })
      : [];

    return columns;
  }, [data]);

  return (
    <div className="energy-control">
      <h4>
        {isLoading ? (
          <Skeleton.Input style={{ height: 30 }} active />
        ) : (
          data?.title || ""
        )}
      </h4>
      <div className="mb-4">
        {isLoading ? (
          <Skeleton.Input style={{ height: 20 }} active />
        ) : (
          data?.subtitle || ""
        )}
      </div>

      <div className="flex items-center justify-between">
        <Radio.Group onChange={onChange} value={controlFilter}>
          <Radio.Button value="all">All</Radio.Button>

          {!_.isEmpty(hashs) &&
            hashs.map((item: any, index: number) => (
              <div
                className="ant-radio-button flex flex-col items-center"
                key={index}
              >
                <Radio.Button value={item.hash}>{item.hash}</Radio.Button>
                <p
                  style={{ color: "#ADADAD" }}
                  className="font-medium text-[13px] mt-0.5"
                >
                  {dayjs.unix(item.timestamp).format("DD/MM HH:mm")}
                </p>
              </div>
            ))}
        </Radio.Group>

        <Button
          size="large"
          disabled={_.isEmpty(selectedNodeIds)}
          type="primary"
          loading={isPending}
          onClick={handleSendSelectedRow}
        >
          envoi
        </Button>
      </div>
      <div className="ag-theme-quartz mt-4">
        <AgGridReact
          ref={gridApi}
          loadingCellRenderer={isLoading}
          rowData={rowData}
          columnDefs={colDefs}
          columnTypes={colTypes}
          suppressRowHoverHighlight={true}
          onCellClicked={handleClickCell}
          onRowSelected={handleChangeSelectedRow}
          rowSelection={{ mode: "multiRow" }}
        />
      </div>
      {contextHolder}
    </div>
  );
}

export default ControlPage;
