const PAGES = {
  home: "/",
  edit: "/edit/:id",
  list: "/list",
  settings: "/settings",
  homeResult: "/dashboard/:id",
  insight: "/insight/:id",

  acheter: "/acheter",
  vendre: "/vendre",
  renover: "/renover",
  reCalculate: "/re-calcul",
  controls: "/controls",
};

export const getHomeResultURL = (id: string) => {
  return PAGES.homeResult.replace(":id", id);
};

export const getInsightURL = (id: string) => {
  return PAGES.insight.replace(":id", id);
};

export default PAGES;
