export const paths = {
  mockData() {
    return `https://pst2ptf774f5e6tabql7g4tle40dyetm.lambda-url.eu-west-1.on.aws`;
  },
  modalData() {
    return `https://tnorlolbmzkwo6gxf5u5awukke0senxc.lambda-url.eu-west-1.on.aws`;
  },
  autoCompleteSearch(search: string) {
    return `https://api-adresse.data.gouv.fr/search/?q=${search}`;
  },
  listingTable() {
    return "https://akwaiji6qo3z5qy2tyeq4amvh40uhqfc.lambda-url.eu-west-1.on.aws";
  },
  getDataUpload(fileName: string) {
    return `https://zlmqrmqcbckmsovecorupci2qu0uvcxr.lambda-url.eu-west-1.on.aws/?file_name=${fileName}`;
  },
  getHomeUploadData() {
    // return `https://clzsw527uy4kzfl6btmf2wqnd40ipazn.lambda-url.eu-west-1.on.aws`;
    return `https://clzsw527uy4kzfl6btmf2wqnd40ipazn.lambda-url.eu-west-1.on.aws/`;
  },
  getResultat() {
    // return "https://cfq45qf2zqdh63lbs55pieskia0merqq.lambda-url.eu-west-1.on.aws/";
    return "https://cfq45qf2zqdh63lbs55pieskia0merqq.lambda-url.eu-west-1.on.aws/";
  },
  getGEOMap() {
    return "https://gist.githubusercontent.com/wavded/1200773/raw/e122cf709898c09758aecfef349964a8d73a83f3/sample.json";
  },
  submitResultat() {
    return "https://keddeb4tq2jpb5m4n6hlq3x7hq0isjcu.lambda-url.eu-west-1.on.aws";
  },
  getDataFiabilite() {
    return "https://dl2qumc36h7w5ov3cy7krchluq0uudao.lambda-url.eu-west-1.on.aws";
  },
  getResultAutocomplete() {
    return "https://qyvf5sswyc3e7sdjvaiefi4omq0uydga.lambda-url.eu-west-1.on.aws";
  },
  getReCalCulateResult() {
    return "https://vwpw34wpop5cbyr22frtfoppzy0nzlxl.lambda-url.eu-west-1.on.aws/?id=dd";
  },
  getControlsResult() {
    return "https://m7grwyxqxxj5veumr3agw7o5mi0rubdn.lambda-url.eu-west-1.on.aws";
  },
  postSelectedRowControl() {
    return "https://kkpiigdrqdpl3r4iugqaqc3e440dmkcf.lambda-url.eu-west-1.on.aws";
  },
};
