import { createHashRouter } from "react-router-dom";

import App from "App";
import HomePage from "pages/home";
import ListPage from "pages/list";
import EditPage from "pages/edit";
import SettingsPage from "pages/settings";
import HomeResultPage from "pages/home-result";
import InsightPage from "pages/insight";
import ReCalCulPage from "pages/re-calcul";
import ControlPage from "pages/control";

import PAGES from "./constants";
// import { useEffect } from "react";

// function Redirect({ to }: { to: string }) {
//   const navigate = useNavigate();
//   useEffect(() => {
//     navigate(to);
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);
//   return null;
// }

export const router = createHashRouter([
  {
    path: PAGES.home,
    element: <App />,
    // loader: rootLoader,
    children: [
      {
        path: PAGES.home,
        element: <HomePage />,
      },
      {
        path: PAGES.acheter,
        element: <HomePage />,
      },
      {
        path: PAGES.vendre,
        element: <HomePage />,
      },
      {
        path: PAGES.renover,
        element: <HomePage />,
      },
      {
        path: PAGES.list,
        element: <ListPage />,
      },
      {
        path: PAGES.settings,
        element: <SettingsPage />,
      },
      {
        path: PAGES.homeResult,
        element: <HomeResultPage />,
      },
      {
        path: PAGES.insight,
        element: <InsightPage />,
      },
      {
        path: PAGES.reCalculate,
        element: <ReCalCulPage />,
      },
      {
        path: PAGES.controls,
        element: <ControlPage />,
      },
    ],
  },
  {
    path: PAGES.edit,
    element: <EditPage />,
    // loader: rootLoader,
  },
]);
